:root {
  --primary: #003EB0;
  --secondary: #97AFDD;
  --black: #000000;
}

.app-container {
  
  max-height: 720px;
  margin: 0 auto;
  overflow: hidden;
}

header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px 30px;
}

.logo {
  width: 200px;
}

.date-time {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-end;
  text-align: end;
  line-height: 1;
  text-transform: lowercase;
}

#time {
  font-size: 36px;
  font-weight: 700;
  margin-left: 18px;
}

#date {
  font-size: 20px;
  font-weight: 400;
  margin-left: 18px;
}


main {
  padding: 0 30px;
  width: 100%;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary);
  margin-bottom: 12px;
}

.section-now {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-bottom: 36px;
  overflow: hidden;
}

.section-next {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  overflow: hidden;
}

.event-small {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
  gap: 8px;
  position: relative;
}

.event-small::before, 
.event-small::after {
   content: '';
   position: absolute;
   background-color: #d3d3d3;
   z-index: 1;
}
.event-small::after {
  inline-size: 100vw;
  block-size: 1px;
  inset-inline-start: 0;
  inset-block-start: -12px;
}
.event-small::before {
  inline-size: 1px;
  block-size: 100vh;
  inset-block-start: 0;
  inset-inline-start: -12px;
}

.event-large {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
  gap: 24px;
  position: relative;
}

.event-large::before, 
.event-large::after {
   content: '';
   position: absolute;
   background-color: #d3d3d3;
   z-index: 1;
}
.event-large::after {
  inline-size: 100vw;
  block-size: 1px;
  inset-inline-start: 0;
  inset-block-start: -18px;
}
.event-large::before {
  inline-size: 1px;
  block-size: 100vh;
  inset-block-start: 0;
  inset-inline-start: -18px;
}
.event-time {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.event-large .event-time {
  width: 120px;
}

.event-small .event-hour {
  font-size: 30px;
  font-weight: 600;
  line-height: 0.95;
}

.event-large .event-hour {
  font-size: 40px;
  font-weight: 600;
  line-height: 0.95;
}

.event-small .event-minutes {
  font-size: 30px;
  font-weight: 600;
  margin-left: 3px;
}

.event-large .event-minutes {
  font-size: 36px;
  font-weight: 600;
  margin-left: 3px;
}
.event-small .event-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.event-small .event-text .event-text-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-small .event-title {
  font-size: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1;
  max-height: 3em;
}

.event-large .event-title {
  font-size: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.1;
  max-height: 3.3em;
}

.event-small .event-subtitle {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.event-large .event-subtitle {
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-small .event-aula {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 6px 10px;
  font-size: 13px;
  width: fit-content;
  font-weight: 700;
  gap: 8px;
}

.event-large .event-aula {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 7px 12px;
  font-size: 16px;
  width: fit-content;
  font-weight: 700;
  margin-top: 16px;
  gap: 8px;
}

.event-small .event-aula-icon {
  height: 12px;
}

.event-large .event-aula-icon {
  height: 14px;
}

.no-classes {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 100px 0;
}

.no-classes-icon {
  width: 60px;
  margin-bottom: 30px;
}